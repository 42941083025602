<template>

	<div>
		<div class="block-header">
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<div>
								<el-button type="primary" size="medium" style="float: right;" @click="submit">保存
								</el-button>
								<el-page-header @back="go('/project-effective-list')" :content="'有效项目--'+operation">
								</el-page-header>
							</div>

							<el-form ref="form" label-width="160px" size="small">

								<el-row>
									<el-col :span="10">
										<el-form-item label="填报单位">
											<el-tag type="success">{{form.填报单位}}</el-tag>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="项目名称" required>
											<el-input v-model="form.项目名称"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="建设内容" required>
											<el-input type="textarea" :rows="4" v-model="form.建设内容"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="拟投资额(万元)">
											<el-input-number v-model="form.总投资额" controls-position="right" :min="1">
											</el-input-number>
										</el-form-item>
									</el-col>
								</el-row>


								<el-form-item label="产业类别" required>
									<el-select v-model="form.产业类别">
										<el-option label="工业" value="工业"></el-option>
										<el-option label="农业" value="农业"></el-option>
										<el-option label="服务业" value="服务业"></el-option>
									</el-select>
								</el-form-item>

								<el-row>
									<el-col :span="10">
										<el-form-item label="建设地点">
											<el-input v-model="form.建设地点"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="用地面积">
											<el-input v-model="form.用地面积"></el-input>
										</el-form-item>
									</el-col>
								</el-row>


								<el-row>
									<el-col :span="10">
										<el-form-item label="投资方" required>
											<el-input v-model="form.投资方名称"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="投资方所属地">
											<place-select v-model="form.投资方所属地"></place-select>
										</el-form-item>
									</el-col>
								</el-row>


								<el-row>
									<el-col :span="8">
										<el-form-item label="联系人" required>
											<el-input v-model="form.联系人"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="8">
										<el-form-item label="联系人电话" required>
											<el-input v-model="form.联系人电话"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="8">
										<el-form-item label="联系人职务">
											<el-input v-model="form.联系人职务"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="跟踪单位">
											<el-input v-model="form.跟踪单位"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="跟踪人">
											<el-input v-model="form.跟踪人"></el-input>
										</el-form-item>
									</el-col>
								</el-row>


								<el-row>
									<el-col :span="10">
										<el-form-item label="备注描述">
											<el-input v-model="form.备注描述"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

							</el-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import PlaceSelect from '../components/PlaceSelect.vue'
	import projectEffectiveController from '../controller/projectEffectiveController.js'
	export default {
		components:{
			PlaceSelect
		},
		data() {
			return {
				operation: '',
				id: '',
				form: {},
				user: {},
			}
		},
		created() {
			this.id = this.getParam('id');
			this.user = JSON.parse(sessionStorage.getItem('user'))

			if (this.id) {
				this.operation = '编辑';
				this.initForm();
			} else {
				this.operation = '填报';
				this.form.填报单位 = this.user.dept_name
			}

		},
		methods: {
			initForm() {
				projectEffectiveController.get({
					unique: this.id
				}).then(res => {
					this.form = res.data
				})
			},
			submit() {
				if (!this.validate())
					return;

				let obj = this.deepClone(this.form)
				if (obj.id) {
					projectEffectiveController.put(obj).then(res => {
						this.go('/project-effective-list')
					})
				} else {
					projectEffectiveController.post(obj).then(res => {
						this.go('/project-effective-list')
					})
				}

			},
			validate() {

				if (!this.form.项目名称) {
					this.$message.error('请填写项目名称')
					return false;
				}

				if (!this.form.建设内容) {
					this.$message.error('请填写建设内容')
					return false;
				}

				if (!this.form.产业类别) {
					this.$message.error('请填写产业类别')
					return false;
				}
				
				if (!this.form.投资方名称) {
					this.$message.error('请填写投资方')
					return false;
				}

				if (!this.form.联系人) {
					this.$message.error('请填写联系人')
					return false;
				}

				if (!this.form.联系人电话) {
					this.$message.error('请填写联系人电话')
					return false;
				}

				return true;

			}
		}

	}
</script>

<style scoped>
	.el-page-header {
		margin-bottom: 40px;
	}
</style>
